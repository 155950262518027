import useGame from "../../modules/game/hooks/use-game";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { getGeneratorRoute } from "../../routes";

//@ts-ignore
import * as sanitizeHtml from 'sanitize-html';
type PopupProps = {
    task: Task,
    onClick: React.MouseEventHandler
}

export default function Popup({ task, onClick }: PopupProps) {

    const { game } = useGame();

    const ending: string[] = task ? (typeof task.ending === 'string' ? [task.ending] : task.ending) : [];

    useEffect(() => {
        window.document.body.style.overflow = 'hidden';

        return () => {
            window.document.body.style.overflow = 'auto';
        }
    })

    return (
        <div className="popup">
            <div className="container">
                <div className="inner">
                    <h3>Oto wasza nagroda</h3>
                    {ending.map((d, index) => {
                        return (
                            <p key={index} dangerouslySetInnerHTML={{ __html: sanitizeHtml(d) }}>
                            </p>
                        )
                    })
                    }

                    <div className="img-list">
                        <div className="awards">
                            {
                                task.awards.map(
                                    a => game.generator.stickers.find(
                                        s => s.id === a)
                                ).map((s, index) => {
                                    return (<div key={index}><img src={s?.iconSrc} alt={s?.id} /></div>)
                                })
                            }
                        </div>
                    </div>

                    <nav>
                        <div className="left">
                            <Link className="button" to={getGeneratorRoute}>Przejdź{'\u00A0'}do{'\u00A0'}zamku</Link>
                        </div>
                        <div className="rigth">
                            <button className="button" onClick={onClick}>Rozwiń{'\u00A0'}mapę</button>
                        </div>
                    </nav>
                </div>

            </div>

        </div>
    )
}
