
import CastleGenerator from './CastleGenerator';

export type GameConfig = {
    container: any
    objects: {
        src: string,
        iconSrc: string,
        key: string,
        isAvaiable: boolean,
        unavaialbeLabel: string
    }[],
    state: {
        stickers: StickerState[],
        walls?: WallsData
    }
    onChange: (state: GameConfig['state']) => void,
    userName: string,
    emblem: string
}

export type WallsData = {
    left: number,
    center: number,
    right: number
}

export type StickerState = {
    key: string,
    position: {
        x: number
        y: number,
    }
}

const BASE_WIDTH = 1366;
const BASE_HEIGHT = 950;

export default function createGame(config: GameConfig) {

    const app = new CastleGenerator(BASE_WIDTH, BASE_HEIGHT, 0xfff8e6, config)

    config.container.appendChild(app.view);
    app.gameResize();

    // ====================== //

    return {
        getSceenBlob: () => app.getSceenBlob(),
        uninstall: () => {
            app.destroy();
        },
        reset: () => {
            app.reset();
        }
    };
}





