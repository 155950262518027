import * as PIXI from 'pixi.js';

export class Sticker extends PIXI.Sprite {

    private dragging = false;
    private data?: PIXI.interaction.InteractionEvent['data'];

    constructor(texture: PIXI.Texture, public x: number, public y: number, public key: string, creactionEvent?: PIXI.interaction.InteractionEvent) {
        super(texture);
        this.interactive = true;
        this.buttonMode = true;
        this.anchor.set(0.5);

        if (creactionEvent) {
            this.onDragStart(creactionEvent);
        }

        this
            .on('pointerdown', this.onDragStart)
            .on('pointermove', this.onDragMove)
            .on('pointerup', this.onDragEnd)
            .on('pointerupoutside', this.onDragEnd);
    }
    private onDragMove() {
        if (this.dragging && this.data) {
            const newPosition = this.data.getLocalPosition(this.parent);
            this.x = newPosition.x;
            this.y = newPosition.y;
        }
    }
    private onDragStart(event: PIXI.interaction.InteractionEvent) {
        // store a reference to the data
        // the reason for this is because of multitouch
        // we want to track the movement of this particular touch
        this.data = event.data;
        this.alpha = 0.5;
        this.dragging = true;
    }
    onDragEnd(this: any) {
        this.alpha = 1;
        this.dragging = false;
        // set the interaction data to null
        this.data = null;
    }
}
