import React, { useEffect } from 'react';

import CenterLolumnLayout from '../../layout/screen';
import './style.scss';
import { useSetFullImageColorBackground } from '../../layout/background';
import { Link } from 'react-router-dom';
import PageFooter from '../../components/page-footer';
import { getRulesRoute } from '../../routes';
import { useShowAvailable } from '../../App';

export default function HomePage() {

    const setBackground = useSetFullImageColorBackground();

    useEffect(() => {
        setBackground('/images/backgrounds/ule.jpg')
    })

    const isShowAvailable = useShowAvailable();

    return (
        <CenterLolumnLayout footer={PageFooter()}>
            <main className='column home-screen'>
                <a href="//polin.pl" className="polin">
                    <img src="/images/logo/polin-simple.png" alt="Polin - Muzeum Historii Żydów Polskich" />
                </a>
                <h1>Witajcie!</h1>
                <p>Król Maciuś bardzo się cieszy z Waszego przybycia! Razem ze swoimi dworzanami przygotował dla Was podróż pełną zadań. Wykonując je udowodnicie, że jesteście najlepszymi rycerzami i rycerkami Króla Maciusia.</p>
                <p>Z królewskiego zamku prowadzą cztery drogi. Każda ścieżka to inny temat wyzwań. Czekają Was spotkania ze sztuką, kulturą innych narodów, przyrodą oraz specjalna wycieczka do Muzeum POLIN.</p>
                <p>Za uczestnictwo w turnieju dostaniecie od króla świeżo wybudowany zamek. Po ukończeniu każdego zadania otrzymacie nagrody, którymi będziecie mogli wyposażyć i ozdobić komnaty swojej nowej siedziby.</p>
                <p>Pamiętajcie, w wykonaniu zadań możecie zawsze liczyć na pomoc Waszych dorosłych Opiekunów!</p>

                <Link to={getRulesRoute()} className='button-big-blue'>Rozpocznij przygodę</Link>
                {isShowAvailable && <a href='https://vimeo.com/423126732' className='to-spectakl'><strong>Obejrzyj spektakl</strong><span>*31.05 od 15:00 do 1.06 do 15.00</span></a>}
            </main>
        </CenterLolumnLayout>
    )
}
