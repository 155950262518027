import React, { useCallback, useState, useEffect } from "react";
import AudioPlayer from "../audio-player";
import './style.scss';

export default function AudioQuiz() {

    const DATA = [
        {
            audio: 'https://www.polin.pl/sites/default/files/2020-05/kogut%201%20%20-%20freesound.org_.wav',
            correct: 'kogut',
            answers: ['indyk', 'kogut', 'bażant']
        },
        {
            audio: 'https://www.polin.pl/sites/default/files/2020-05/lew%20-%20animal-sound.org_.wav',
            correct: 'lew',
            answers: ['lew', 'dzik', 'słoń']
        },
        {
            audio: 'https://www.polin.pl/sites/default/files/2020-05/owce%20-%20freesound.org_.wav',
            correct: 'owca',
            answers: ['krowa', 'kruk', 'owca']
        },
        {
            audio: 'https://www.polin.pl/sites/default/files/2020-05/wiewiorka%20-%20freesound.org_.wav',
            correct: 'wiewiórka',
            answers: ['sikorka', 'lis', 'wiewiórka']
        },
        {
            audio: 'https://www.polin.pl/sites/default/files/2020-05/jelen%20-%20soundbible.com_.mp3',
            correct: 'jeleń',
            answers: ['jeleń', 'osioł', 'koza']
        }
    ]

    const correctChangeHandler = useCallback((state: boolean) => {
    }, [])

    return (
        <div className="audio-test">
            {DATA.map((d, index) => {
                return (
                    <Question
                        key={index}
                        audio={d.audio}
                        answers={d.answers}
                        correct={d.correct}
                        correctChangeHandler={correctChangeHandler}
                    />
                )
            })}
        </div>
    )
}


type QProps = {
    audio: string,
    answers: string[],
    correct: string,
    correctChangeHandler: (state: boolean) => void
}

const Question: React.FunctionComponent<QProps> = ({ audio, answers, correct, correctChangeHandler }) => {

    const [isCorrect, setIsCorrect] = useState(false);
    const [selected, setSelected] = useState<string>('');

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setSelected(e.currentTarget.value);
        setIsCorrect((e.currentTarget.value === correct));
    }, [correct])

    useEffect(() => {
        correctChangeHandler(isCorrect)
    }, [isCorrect, correctChangeHandler])

    return (
        <div className={`question ${isCorrect ? 'correct' : ''}`}>

            <AudioPlayer
                src={audio}
            />

            <div className="answers">
                {
                    answers.map((a, index) => {
                        return (
                            <label
                                key={index}
                                className={`answer ${correct === a ? 'correct' : ''} ${selected === a ? 'selected' : ''}`}
                                tabIndex={0}
                            >
                                <input
                                    type="radio"
                                    name={`q_${correct}`}
                                    value={a}
                                    onChange={onChange}
                                /> {a}
                            </label>)
                    })
                }
            </div>
        </div>
    )

}
