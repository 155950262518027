import * as PIXI from 'pixi.js';

import { WallsData } from './game';
import { CastleRoom } from "./CastleRoom";


const COLOR_BUTTON_Y = 620;

export class Castle extends PIXI.Container {
    private leftRoom = new CastleRoom([60, 250, 250, 320]);
    private centerRoom = new CastleRoom([400, 200, 500, 370]);
    private rightRoom = new CastleRoom([1020, 250, 250, 320]);
    private buttons: PIXI.Graphics[] = [];
    private onChange: () => void;

    private castleSprite?: PIXI.Sprite;
    constructor(props: {
        castle: PIXI.Texture;
        onChange: () => void;
        walls?: WallsData;
    }) {
        super();
        this.onChange = props.onChange;
        this.addChild(this.leftRoom);
        this.leftRoom.setSolidColor(0xdaf2f2);
        this.addChild(this.centerRoom);
        this.centerRoom.setSolidColor(0xdaf2f2);
        this.addChild(this.rightRoom);
        this.rightRoom.setSolidColor(0xdaf2f2);

        this.castleSprite = new PIXI.Sprite(props.castle)
        this.addChild(this.castleSprite);

        this.addColorButton(60, COLOR_BUTTON_Y, 0xdaf2f2, this.leftRoom);
        this.addColorButton(110, COLOR_BUTTON_Y, 0xe7fce5, this.leftRoom);
        this.addColorButton(160, COLOR_BUTTON_Y, 0xffe9c9, this.leftRoom);
        this.addColorButton(210, COLOR_BUTTON_Y, 0xfae5f6, this.leftRoom);
        this.addColorButton(400, COLOR_BUTTON_Y, 0xdaf2f2, this.centerRoom);
        this.addColorButton(450, COLOR_BUTTON_Y, 0xe7fce5, this.centerRoom);
        this.addColorButton(500, COLOR_BUTTON_Y, 0xffe9c9, this.centerRoom);
        this.addColorButton(550, COLOR_BUTTON_Y, 0xfae5f6, this.centerRoom);
        this.addColorButton(1030, COLOR_BUTTON_Y, 0xdaf2f2, this.rightRoom);
        this.addColorButton(1080, COLOR_BUTTON_Y, 0xe7fce5, this.rightRoom);
        this.addColorButton(1130, COLOR_BUTTON_Y, 0xffe9c9, this.rightRoom);
        this.addColorButton(1180, COLOR_BUTTON_Y, 0xfae5f6, this.rightRoom);
        if (props.walls) {
            this.rightRoom.setSolidColor(props.walls.right);
            this.centerRoom.setSolidColor(props.walls.center);
            this.leftRoom.setSolidColor(props.walls.left);
        }

        //@ts-ignore
        // window['xxxx'] = this;
    }

    public getYCorrection(): number {
        if (this.castleSprite) {
            const top = this.getLocalBounds().y;
            return -top;
        }

        return 0;
    }
    private addColorButton(x: number, y: number, color: number, target: CastleRoom) {
        const button = new PIXI.Graphics();
        button.beginFill(color);
        button.drawRect(x, y, 40, 40);
        button.endFill();
        button.interactive = true;
        button.buttonMode = true;
        button.on('click', () => {
            target.setSolidColor(color);
            this.onChange();
        });
        this.addChild(button);
        this.buttons.push(button);
    }
    public hideUX() {
        this.buttons.forEach(b => b.visible = false);
    }
    public showUX() {
        this.buttons.forEach(b => b.visible = true);
    }
    public getWallsColors() {
        return {
            left: this.leftRoom.color,
            center: this.centerRoom.color,
            right: this.rightRoom.color
        };
    }
}
