import * as PIXI from 'pixi.js';

const grayscale = new PIXI.filters.ColorMatrixFilter();
grayscale.greyscale(0.2, false);



export class AlbumButton extends PIXI.Sprite {
    constructor(texture: PIXI.Texture, public x: number, public y: number, public key: string, isAvaiable = true) {
        super(texture);


        this.interactive = true;
        this.buttonMode = true;
        this.anchor.set(0.5);

        if (!isAvaiable) {
            this.filters = [grayscale];
        }

        this.scale.set(Math.min(100 / this.texture.height, 100 / this.texture.width, 1));
    }
}
