import React, { useEffect } from 'react';
import styled from 'styled-components';
import CenterLolumnLayout from '../../layout/screen';
import './style.scss';
import { useSetFullImageColorBackground } from '../../layout/background';
import { Link, useLocation } from 'react-router-dom';
import PageFooter from '../../components/page-footer';
import { getLoginRoute, getMapRoute } from '../../routes';


const IntroScreen = styled.main``

export default function IntroPage() {

    const { state } = useLocation<{ fromMap: boolean }>();

    const showGoToMap = state ? state.fromMap : false;
    const showGoToLogin = !showGoToMap;

    const setBackground = useSetFullImageColorBackground();
    useEffect(() => {
        setBackground('/images/backgrounds/start.jpg');
    })

    return (
        <CenterLolumnLayout footer={PageFooter()}>
            <IntroScreen className='intro-screen column'>
                <h1>Zasady turnieju</h1>

                <ol>
                    <li>Na kolejnej stronie wpiszcie swoje imię/pseudonim lub nazwę Waszej drużyny oraz wybierzcie Wasz herb.</li>
                    <li>Podczas turnieju możecie podążyć czterema ścieżkami, które są pokazane na mapie.</li>
                    <li>Zadania do wykonania są oznaczone kwiatami poruszającymi się na wietrze.</li>
                    <li>W niektórych zadaniach napotkacie karty pracy. Możecie je pobrać na komputer klikając w przycisk “Pobierz”, a następnie wydrukować.</li>
                    <li>Jeśli nie macie w domu drukarki, możecie kartę pracy odrysować . W tym celu:
                        <ul>
                            <li>kliknijcie w widoczną kartę pracy</li>
                            <li>taśmą klejącą przyklejcie kartkę do ekranu</li>
                            <li>ołówkiem odrysujcie prześwitujący obrazek</li>
                            <li>wykonajcie dalszą część zadania</li>
                        </ul>
                    </li>
                    <li>Ukończenie zadania musicie potwierdzić klikając w przycisk „Zadanie wykonane”.</li>
                    <li>Aby wykonać następne zadanie, najpierw musicie ukończyć to, które jest dla Was dostępne.</li>
                    <li>Za ukończenie zadania otrzymacie nagrody.</li>
                    <li>Nagrody możecie wykorzystać w Waszym zamku. By do niego przejść, kliknijcie w tabliczkę z napisem: „Budowa zamku”.</li>
                    <li>W zamku możecie ustawiać wiele razy tę samą nagrodę oraz wybrać kolory, którymi pomalujecie ściany komnat.</li>
                    <li>Gdy skończycie dekorować swój zamek, możecie go zachować, klikając w przycisk z napisem: „Pobierz projekt”.</li>
                    <li>Aby wrócić później do spisu zasad, kliknijcie na mapie w chorągiewkę z napisem: „Zasady”.</li>
                </ol>

                {showGoToLogin && <Link to={getLoginRoute()} className="button-big-blue">Dalej</Link>}
                {showGoToMap && <Link to={getMapRoute()} className="button-big-blue">Rozwiń mapę</Link>}
            </IntroScreen>
        </CenterLolumnLayout>
    )
}
