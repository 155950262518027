import * as PIXI from 'pixi.js';

export class CastleRoom extends PIXI.Container {
    private solid = new PIXI.Graphics();
    public color: number = 0xffffff;
    constructor(private cords: [number, number, number, number]) {
        super();
        this.addChild(this.solid);
    }
    public setSolidColor(color: number) {
        // this.solid.clear();
        this.color = color;
        this.solid.beginFill(color);
        this.solid.drawRect(...this.cords);
        this.solid.endFill();
    }
}
