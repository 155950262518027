import React from "react";
import TaskElements from "../../taks-elements";
import './style.scss'

type Props = {
    task: Task
}

const Task203: React.FunctionComponent<Props> = ({ task }) => {
    return (
        <div className="task-screen column task-203">

            <h1>{task.title}</h1>

            <p>Król Maciuś uwielbia grę w drejdla. Postanowił, że w trakcie podróży zagra ze wszystkimi, którzy mu towarzyszą. Dlatego musicie się nauczyć zasad tej gry. Do treningu przygotujcie własny bączek, który możecie zrobić nawet z kawałka tektury i kredki. Przeczytajcie zasady i postarajcie się wygrać.</p>


            <TaskElements
                elements={task?.elements || []}
            />
        </div>)
}

export default Task203;
