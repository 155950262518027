import React from "react";
import './style.scss'
import TaskElements from "../../taks-elements";

type Props = {
    task: Task
}

const Task202: React.FunctionComponent<Props> = ({ task }) => {
    return (
        <div className="task-screen column task-202">

            <h1>{task.title}</h1>

            <p>Król pragnie, byście poznali trochę kulturę państw, które odwiedzicie jako jego rycerze i rycerki. Z szacunku dla ich mieszkańców zróbcie sobie tradycyjne nakrycia głowy. Korzystając z przygotowanych przez królewskich stylistów pomocy, wykonajcie teraz wybraną przez siebie ozdobę na głowę. Przymierzcie ją i zróbcie sobie zdjęcie ― pokażcie, jak się prezentujecie!</p>
            <p>
            Skorzystajcie z instrukcji i gotowych szablonów.
            </p>
                <ol>
                    <li>Wydrukujcie lub przerysujcie wybrane nakrycie głowy.</li>
                    <li>Pokolorujcie i wytnijcie je.</li>
                    <li>Z bloku technicznego wytnijcie dwa grube paski.</li>
                    <li>Przyłóżcie paski do głowy, tak, by tworzyły obręcz i dopasujcie ich długość.</li>
                    <li>Paski sklejcie ze sobą.</li>
                    <li>Do powstałej obręczy przyklejcie nakrycie głowy.</li>
                </ol>

            <TaskElements
                elements={task?.elements || []}
            />
        </div>)
}

export default Task202;
