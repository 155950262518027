import React, { useEffect, useRef, useCallback, useState } from "react";
import './style.scss';
import { getGeneratorRoute, getTaskRoute, getRulesRoute, getEndRoute } from "../../routes";
import { Link, useHistory } from "react-router-dom";

import * as Bowser from "bowser";
import useUserCompletedTasks from "../../modules/user/hooks/use-user-completed-tasks";
import useTask from "../../modules/game/hooks/use-task";
import { useShowAvailable } from "../../App";
const browser = Bowser.getParser(window.navigator.userAgent);


const SWITCH_RATIO = 1.2;

export default function MapV2() {

    const [lClass, setLayoutClass] = useState('');
    const history = useHistory();

    const mainContainer = useRef<HTMLDivElement>(null);
    const mapContainer = useRef<HTMLDivElement>(null);

    const isShowAvailable = useShowAvailable();

    const resize = useCallback(() => {
        const wW = window.innerWidth;
        const wH = window.innerHeight;

        const wRatio = wW / wH;

        if (mapContainer.current && mainContainer.current) {

            const isMobile = browser.is('mobile');
            const isColumn = wRatio >= SWITCH_RATIO;

            if (isColumn && !isMobile) {
                setLayoutClass(`map-column desktop`);
                mapContainer.current.style.zoom = '' + window.devicePixelRatio;
                mapContainer.current.style.height = `auto`
                return;
            }

            if (isColumn && isMobile) {
                setLayoutClass(`map-column mobile`);
                return;
            }

            if (!isColumn && !isMobile) {
                setLayoutClass(`map-row desktop`);
                mapContainer.current.style.zoom = '' + window.devicePixelRatio;
                mapContainer.current.style.height = `${100 * window.devicePixelRatio}%`
                return;
            }

            if (!isColumn && isMobile) {
                setLayoutClass(`map-row mobile`);
            }
        }

    }, [])

    useEffect(() => {
        setTimeout(resize)
        // setTimeout(resize, 10000)
        // resize();

        window.addEventListener('resize', resize);

        return () => window.removeEventListener('resize', resize);
    })

    const completedTasks = useUserCompletedTasks();

    const getTaskState = useCallback((id: number) => {
        if (completedTasks.find(t => t === id)) {
            return 'completed'
        }

        if (completedTasks.find(t => t === id - 1)) {
            return 'active'
        }

        if (id === 101 || id === 201 || id === 301 || id === 401) {
            return 'active'
        }

        return 'future';
    }, [completedTasks])

    const toRulesClicked = useCallback((e: React.MouseEvent) => {
        e.preventDefault()
        history.push(getRulesRoute(), {
            fromMap: true
        })
    }, [history])

    return (
        <>
            <main className={`map-o ${lClass}`} ref={mapContainer}>
                <div className="map-c" ref={mainContainer}>
                    <div className="map-c2">
                        <div className="map-c3">
                            <div className="polin-flag">
                                <a href='https://polin.pl' className='do-muzeum'>Polin - Muzeum Historii Żydów Polskich</a>
                            </div>
                            <Link to={getGeneratorRoute()} className="do-zamku">Do zamku</Link>
                            <Link to={getRulesRoute()} onClick={toRulesClicked} className="zasady">Zasady</Link>
                            {isShowAvailable && <a href="https://vimeo.com/423126732" className='na-spektakl'>Obejrzyj spektakl</a>}


                            {
                                [
                                    101, 102, 103, 104, 105,
                                    201, 202, 203, 204, 205, 206,
                                    301, 302, 303, 304, 305, 306,
                                    401, 402, 403, 404, 405, 406, 407
                                ].map(id => (
                                    <TaskLink key={id} id={id} state={getTaskState(id)} />
                                ))
                            }

                            <div className="to-end">
                                <Link to={getEndRoute()} className="button-big-blue">Zakończ turniej</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            {/* <h1>Niezwykła kraina Króla Maciusia</h1> */}

        </>
    )
}


type TLProps = {
    id: number,
    state: string
}


function TaskLink({ id, state }: TLProps) {
    const { task } = useTask(id);

    return (
    <Link to={getTaskRoute(task?.id)} tabIndex={state === 'future' ? -1 : 0} className={`task t-${task?.id} ${state}`}>{task?.title} {state === 'completed' ? 'Zadanie wykonane' : 'Zadanie aktywne'}</Link>
    )
}
