import React, { useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import useTask from '../../modules/game/hooks/use-task';
import CenterLolumnLayout from '../../layout/screen';
import { useSetPatternBackground } from '../../layout/background';
import PageFooter from '../../components/page-footer';
import './style.scss';
import { getMapRoute } from '../../routes';
import Task103 from './CustomTasks/Task103';
import Task202 from './CustomTasks/Task202';
import Task203 from './CustomTasks/Task203';
import Task405 from './CustomTasks/Task405';
import Task407 from './CustomTasks/Task407';
import Task104 from './CustomTasks/Task104';

import { Link } from 'react-router-dom';
import DefaultTask from './default-task';

type Props = {
    id?: string
}

const TaskPage: React.FunctionComponent<Props> = ({ id }) => {
    const { id: uri_id } = useParams();
    const { task, isLoading } = useTask(parseInt(id || uri_id));
    const history = useHistory();


    const onCompleteTask = useCallback(() => {
        if (task) {
            history.push(getMapRoute(), {
                task
            })
        }
    }, [task, history])

    const onCompleteTaskClicked = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        onCompleteTask()
    }, [onCompleteTask])

    const setBackground = useSetPatternBackground();
    const patterns = [
        'pattern-1',
        'pattern-2',
        'pattern-3',
        'pattern-4',
        'pattern-5',
        'pattern-7',
        'pattern-8',
        'pattern-9',
    ]

    useEffect(() => {
        setBackground(`/images/backgrounds/${patterns[Math.floor(Math.random() * patterns.length)]}.jpg`)
    }, [id, patterns, setBackground])

    if (!task && !isLoading) {
        return (<p>Coś poszło nie tak</p>)
    }


    return (
        <CenterLolumnLayout footer={PageFooter()}>
            <div className="to-map">
                <Link to={getMapRoute()} className="button-secondary to-map"><span>Rozwiń mapę</span></Link>
            </div>
            {(() => {
                switch (task?.id) {
                    case 103:
                        return (
                            <Task103
                                task={task as Task}
                            />
                        )

                    case 104:
                        return (
                            <Task104
                                task={task as Task}
                            />
                        )

                    case 202:
                        return (
                            <Task202
                                task={task as Task}
                            />
                        )

                    case 203:
                        return (
                            <Task203
                                task={task as Task}
                            />
                        )

                    case 405:
                        return (
                            <Task405
                                task={task as Task}
                            />
                        )



                    case 407:
                        return (
                            <Task407
                                task={task as Task}
                            />
                        )

                    default:
                        return (
                            <DefaultTask
                                task={task as Task}
                            />
                        )

                }
            })()}

            {task?.id !== 407 && <div className="button-complete">
                <a href={getMapRoute()} onClick={onCompleteTaskClicked} className="button-big-blue">Zadanie wykonane</a>
            </div>}

        </CenterLolumnLayout>
    )
}

export default TaskPage;
