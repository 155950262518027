import React, { useState, useEffect, useCallback } from "react"
import './style.scss';

type Props = {
    data: Img[]
}

type Img = {
    src: string,
    description: string
}

const SLIDER_INTERVAL = 5000;

export default function ImageSlider(/*{data}: Props*/) {

    const data = [
        {
            src: '/tasks/204/Albert_Einstein_Jerusalem_Effib_wiki.jpg',
            description: 'Replika pomnika Alberta Einsteina z 1979 r, autor: Robert Berks, Izraelska Akademia Nauk Ścisłych I Humanistycznych, Jerozolima, Izrael.',
            source: {
                label: 'Effib | Wikimedia Commons',
                src: 'https://commons.wikimedia.org/wiki/User:Effib'
            },
            isCC: true
        },
        {
            src: '/tasks/204/Gagarin_moskwa_nastya-petrushin_pixaby.jpg',
            description: 'Pomnik Jurija Gagarina, autor: Pavel Bondarenko, plac Gagarina, Moskwa, 1980.',
            source: {
                label: 'nastya-petrushin | Pixabay',
                src: 'https://pixabay.com/users/nastya-petrushin-1987576/'
            }
        },
        {
            src: '/tasks/204/A-maze-ing_Laughter_vancouver_cameron_norman_flickr.jpg',
            description: 'A-maze-ing Laughter, autor: Yue Minjun, Morton Park, Vancouver, Canada, 2009.',
            source: {
                label: 'cameron_norman | flickr',
                src: 'https://www.flickr.com/people/10316646@N07'
            },
            isCC: true
        },
        {
            src: '/tasks/204/The_Diving _elle_on_St_Vincents_Pier_scarborough_UK_andrew-buchanan_unsplash.jpg',
            description: 'The Diving Belle, autor: Craig Knowles, Vincent Pier, Scarborough, England, 2007.',
            source: {
                label: 'Andrew Buchanan | Unsplash',
                src: 'https://unsplash.com/@photoart2018'
            }
        },
        {
            src: '/tasks/204/maria_sklodowska_curie_warszawa_polska_MirceaIancu_pixabay.jpg',
            description: 'Maria Skłodowska-Curie, autor: Bronisław Krzysztof, Kościelna, Warszawa, 2014.',
            source: {
                label: 'Mirceaiancu | Pixabay',
                src: 'https://pixabay.com/pl/users/mirceaiancu-11873433/'
            }
        },
        {
            src: '/tasks/204/freddie_mercury_motreux_szwajcaria_archie-fantom_unsplash.jpg',
            description: 'Freddie Mercury, autor: Irena Sedlecká, Place du Marché, Montreux, Szwajcaria, 1996.',
            source: {
                label: 'Archie_Fantom | Unsplash',
                src: 'https://unsplash.com/@archie_fantom'
            }
        },

    ]

    const [imgIndex, setImgIndex] = useState(0)
    const [isAutoMode, setAutoMode] = useState(false);

    const nextImage = useCallback(() => {
        setImgIndex((imgIndex + 1) % data.length);
    }, [setImgIndex, imgIndex, data.length])

    const prevImage = useCallback(() => {
        setImgIndex((imgIndex + data.length - 1) % data.length);
    }, [setImgIndex, imgIndex, data.length])

    useEffect(() => {
        if (isAutoMode) {
            const id = setTimeout(() => {
                nextImage();
            }, SLIDER_INTERVAL)
            return () => { clearTimeout(id) }
        }

    }, [imgIndex, nextImage, isAutoMode])


    const toggle = useCallback(() => {
        setAutoMode(!isAutoMode);
    }, [setAutoMode, isAutoMode])

    const next = useCallback(() => {
        nextImage();
    }, [nextImage]);

    const prev = useCallback(() => {
        prevImage();
    }, [prevImage]);

    return (
        <div className="img-slider">

            <figure>
                <div className="image">
                    <div className='controls'>
                        <div className="left">
                            {!isAutoMode && <button onClick={prev} className="prev"></button>}
                        </div>
                        <div className="center">
                            <button onClick={toggle} className={isAutoMode ? 'pause' : 'play'}></button>
                        </div>
                        <div className="right">
                            {!isAutoMode && <button onClick={next} className="next"></button>}
                        </div>
                    </div>
                    <img className="slide" src={data[imgIndex].src} alt="" />
                </div>
                <figcaption>
                    {data[imgIndex].description}<br />
                    <div className="legal">
                        Źródło: <a href={data[imgIndex].source.src}>{data[imgIndex].source.label}</a>
                        {data[imgIndex].isCC && <>
                            <br />
                            <a rel="license" href="http://creativecommons.org/licenses/by/2.0/">
                                <img
                                    alt="Licencja Creative Commons"
                                    style={{ borderWidth: 0 }}
                                    src="https://i.creativecommons.org/l/by/2.0/88x31.png"
                                />
                            </a>Ten utwór jest dostępny na{'\u00A0'}<a rel="license" href="http://creativecommons.org/licenses/by/2.0/">licencji Creative Commons Uznanie autorstwa 2.0 Ogólny</a>.
                    </>}
                    </div>
                </figcaption>
            </figure>
        </div>
    )

}
