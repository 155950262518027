import React from "react";
import './style.scss'

type Props = {
    task: Task
}

const Task103: React.FunctionComponent<Props> = ({ task }) => {



    let audioSrc;

    try {
        audioSrc = task.elements?.find(e => e.type === 'audio')?.params.src;
    } catch (e) {

    }

    return (
        <div className="task-screen column task-103">

            <h1>{task.title}</h1>

            <p>Śpiew bardzo ułatwia równy marsz. Posłuchajcie marszowej pieśni Króla Maciusia pod tytułem Korona. Spróbujcie nauczyć się refrenu i wymyślcie swój własny krok marszowy. Sprawdźcie, czy śpiewając, idzie Wam się lżej.</p>

            <section className='song'>
                <h3>Refren</h3>
                Ciężka korona,<br />
                zimna korona.<br />
                Gorsza od czapki,<br />
                nie ma pompona.<br />
                Choć złotnik zrobi mały diademik,<br />
                swym ciężarem będzie króla zginać aż do ziemi.<br />

            </section>

            {audioSrc && <audio controls src={audioSrc}></audio>}

        </div>)
}

export default Task103;
