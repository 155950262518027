import { useRef, useCallback } from "react";
import React from "react";
import useGame from "../../modules/game/hooks/use-game";
import { getMapRoute, getEndRoute } from "../../routes";
import { Link } from "react-router-dom";
import './style.scss'
import GameComponent from "./game-component";



export default function Generator2() {
    const { game, isLoaded } = useGame();
    const gameRef = useRef<any>();

    const onDownloadClick = useCallback((e: React.MouseEvent) => {
            e.preventDefault();
            gameRef.current.getImage().then((image: string) => {
                if (navigator.msSaveBlob) {
                    navigator.msSaveBlob(b64toBlob(image.replace(/^data:image\/(png|jpeg|jpg);base64,/, '')), 'zamek.png');
                } else {
                    var a = document.createElement('a');
                    a.href = image;
                    a.style.display = 'none';
                    a.setAttribute('download', 'zamek.png');
                    document.body.appendChild(a);
                    a.click();
                }
            })
    }, [gameRef])

    const onResetClick = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        gameRef.current.reset();
    }, [])

    return (
        <div className='generator'>
            {isLoaded && <GameComponent ref={gameRef} game={game} />}

            <div className="under-game">
                <div className="controls">
                    <a className='button' download onClick={onDownloadClick} href="/">Pobierz projekt</a>
                    <Link className='button' to={getMapRoute()} >Rozwiń mapę</Link>
                    <Link className='button' to={getEndRoute()} >Zakończ turniej</Link>
                    <button className='button-secondary' onClick={onResetClick}><span>Reset</span></button>
                </div>
                <div className="rules">
                    <h3>Instrukcja</h3>
                    <ul>
                        <li>Poniżej zamku znajduje się lista nagród. By ją przeglądać, użyjcie niebieskich strzałek po bokach.</li>
                        <li>Nagrody, które zdobyliście, są kolorowe. Nagrody, które dopiero zdobędziecie, są ciemne.</li>
                        <li>Zdobyte nagrody możecie użyć w zamku wielokrotnie np. po zdobyciu telefonu możecie zainstalować go w każdej sali zamku.</li>
                        <li>Jeśli zmienicie zdanie, możecie usunąć nagrodę z zamku, przesuwając ją do kosza. Nie oznacza to utraty tej nagrody z listy.</li>
                        <li>Klikając w kolorowe kwadraciki pod salami zamku, możecie zmienić kolor ich ścian.</li>
                        <li>W dowolnym momencie możecie pobrać obraz zamku. Później możecie go wydrukować, żeby na przykład  powiesić go na ścianie dla ozdoby. W tym celu użyjcie przycisku z napisem: „Pobierz projekt”.</li>

                    </ul>
                </div>
            </div>


        </div>
    )
}


const b64toBlob = (b64Data: string, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
