import CastleGenerator from "./CastleGenerator";
import * as PIXI from 'pixi.js';

export default class Dyplom extends PIXI.Container {

    private frontPlane = new PIXI.Container()
    private backPlane = new PIXI.Container()

    private baseWidth = 3508;
    private baseHeight = 2480;

    constructor(
        private userName: string,
        private emblem: string,
        castle: PIXI.Sprite,
        private app: CastleGenerator,
        private coorection: { x: number, y: number }
    ) {
        super();

        const g = new PIXI.Graphics();
        g.beginFill(0xffffff);
        g.drawRect(0, 0, this.baseWidth, this.baseHeight)
        g.endFill();

        this.addChild(g);

        this.addChild(this.backPlane);

        const g2 = new PIXI.Graphics()
        this.addChild(g2);

        this.addChild(castle)
        this.addChild(this.frontPlane);

        const CASTLE_SCALE = 2;
        castle.scale.set(CASTLE_SCALE);
        castle.anchor.set(.5, 0);
        castle.x = this.baseWidth / 2
        castle.y = (this.baseHeight * .35) - this.coorection.y * CASTLE_SCALE;

        this.initBackPlane();
        this.initFrontPlane();
        this.initTitle();

        /* DEV */
        // this.scale.set(this.app.baseWidth / this.baseWidth)
    }

    private initBackPlane() {
        if (this.app.resources) {

            const g1 = new PIXI.Sprite(this.app.resources.duza_gora_1?.texture);

            g1.anchor.set(.5, 1);
            g1.x = this.baseWidth / 2;
            g1.y = this.baseHeight;
            g1.scale.set(2)

            //---------

            const g2 = new PIXI.Sprite(this.app.resources.duza_gora_2?.texture);

            g2.anchor.set(.5, 1);
            g2.x = this.baseWidth / 4;
            g2.y = this.baseHeight;
            g2.scale.set(2)

            //---------

            const g3 = new PIXI.Sprite(this.app.resources.duza_gora_2?.texture);

            g3.anchor.set(.5, 1);
            g3.x = this.baseWidth * .75;
            g3.y = this.baseHeight;
            g3.scale.set(2)

            //---------

            const g4 = new PIXI.Sprite(this.app.resources.duza_gora_1?.texture);

            g4.anchor.set(.5, 1);
            g4.x = this.baseWidth / 2;
            g4.y = this.baseHeight - g1.height * .5;
            g4.scale.set(2);

            //---------

            this.backPlane.addChild(g3);
            this.backPlane.addChild(g4);
            this.backPlane.addChild(g2);
            this.backPlane.addChild(g1);
        }
    }

    private initFrontPlane() {
        if (this.app.resources) {

            [
                {
                    texture: this.app.resources.zwierzak_1?.texture, x: this.baseWidth * .04, y: this.baseHeight * .9
                },
                {
                    texture: this.app.resources.zwierzak_2?.texture, x: this.baseWidth * .2, y: this.baseHeight * .9
                },
                {
                    texture: this.app.resources.zwierzak_3?.texture, x: this.baseWidth * .31, y: this.baseHeight * .91
                },
                {
                    texture: this.app.resources.zwierzak_4?.texture, x: this.baseWidth * .86, y: this.baseHeight * .9
                },
                {
                    texture: this.app.resources.zwierzak_5?.texture, x: this.baseWidth * .68, y: this.baseHeight * .92
                },
                {
                    texture: this.app.resources.korona_1?.texture, x: this.baseWidth * .07, y: this.baseHeight * .62
                },
                {
                    texture: this.app.resources.korona_2?.texture, x: this.baseWidth * .07, y: this.baseHeight * .3
                },
                {
                    texture: this.app.resources.korona_2?.texture, x: this.baseWidth * .15, y: this.baseHeight * .12
                },
                {
                    texture: this.app.resources.korona_2?.texture, x: this.baseWidth * .9, y: this.baseHeight * .61
                },
                {
                    texture: this.app.resources.korona_2?.texture, x: this.baseWidth * .82, y: this.baseHeight * .19
                },
                {
                    texture: this.app.resources.korona_3?.texture, x: this.baseWidth * .88, y: this.baseHeight * .5
                },
                {
                    texture: this.app.resources.korona_3?.texture, x: this.baseWidth * .3, y: this.baseHeight * .05
                },
                {
                    texture: this.app.resources.korona_3?.texture, x: this.baseWidth * .8, y: this.baseHeight * .05
                },
                {
                    texture: this.app.resources.korona_4?.texture, x: this.baseWidth * .31, y: this.baseHeight * .25
                },
                {
                    texture: this.app.resources.korona_5?.texture, x: this.baseWidth * .6, y: this.baseHeight * .05
                },
            ].forEach(({ texture, x, y }) => {
                const s = new PIXI.Sprite(texture);

                s.anchor.set(.5);

                s.x = x;
                s.y = y;

                this.frontPlane.addChild(s);
            })

            //-----------


            const k11 = new PIXI.Sprite(this.app.resources.korona_1?.texture);

            k11.anchor.set(.5);

            k11.x = this.baseWidth * .68;
            k11.y = this.baseHeight * .92;

            this.frontPlane.addChild(k11);
        }
    }

    private initTitle() {
        const g = new PIXI.Graphics();

        g.lineStyle(15, 0x682F12, 1);
        g.moveTo(this.baseWidth * .2, 200);
        g.lineTo(this.baseWidth * .8, 200);
        g.closePath();

        this.addChild(g);

        //-----------------------

        const text = this.getNameText();

        text.anchor.set(.5, 0);

        text.x = this.baseWidth / 2;
        text.y = 250;

        this.addChild(text);

        //-----------------------

        const sqTop = text.y + text.height + 45;
        const sqSize = 20;

        const sqLineLenght = this.baseWidth * .6;

        const sqCount = (sqLineLenght / (sqSize * 2)) | 0;
        const sqGap = (sqLineLenght - (sqCount * sqSize)) / (sqCount - 1);


        g.lineStyle(0, 0, 0);
        g.beginFill(0x000000);

        for (let i = 0; i < sqCount; i++) {
            g.drawRect(this.baseWidth * .2 + (i * (sqSize + sqGap)), sqTop - sqSize / 2, sqSize, sqSize)
        }

        g.endFill();
    }

    private getNameText(fontSize: number = 100): PIXI.Text {

        let text;

        do {
            text = new PIXI.Text(`Oświadcza się, że właścicielem zamku jest: ${this.userName}\nherbu ${this.emblem}`, {
                fontFamily: 'Lato',
                fontSize,
                fontWeight: 'bold',
                align: 'center',
                fill: 0x45B387,
                wordWrap: true,
                wordWrapWidth: this.baseWidth * .6
            })
            fontSize = fontSize - 5;
        } while (text.height > 250)

        return text;
    }
}
