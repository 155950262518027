import * as PIXI from 'pixi.js';

export class TrashBin extends PIXI.Container {
    private gfx = new PIXI.Graphics();
    private _dropzone?: PIXI.Rectangle;
    public get dropzone() {
        if (this._dropzone)
            return this._dropzone;
        this._dropzone = new PIXI.Rectangle();
        this.getBounds(false, this.dropzone);
        return this._dropzone;
    }
    constructor() {
        super();
        this.addChild(this.gfx);
        this.width = 200;
        this.height = 200;
        this.drawNormal();
        this.interactive = true;
    }
    public drawNormal() {
        this.gfx.clear();
        this.gfx.beginFill(0x909090);
        this.gfx.drawRect(0, 0, 200, 200);
        this.gfx.endFill();
    }
    public drawOver() {
        this.gfx.clear();
        this.gfx.beginFill(0xff5555);
        this.gfx.drawRect(0, 0, 200, 200);
        this.gfx.endFill();
    }
}
