import React from "react"
import MemoryGame from "../../components/memory-game"
import ImageLottery from "../../components/image-lottery"
import DocumentPrezenter from "../../components/document-prezenter"
import AudioPlayer from "../../components/audio-player"
import Puzzle from "../../components/puzzle"
import YouTubeEmbed from "react-youtube-embed"
import WordsGame from "../../components/words-game"
import ImageSlider from "../../components/image-slider"
import ShadowsGame from "../../components/shadows-game"
import AudioQuiz from "../../components/audio-quiz"
import KoronyGame from "../../components/korony-game"
import Plants from "../../components/plants"
import Fishes from "../../components/fishes"

type TEProps = {
    elements: TaskElement[]
}

const TaskElements: React.FunctionComponent<TEProps> = ({ elements }) => {
    return (
        <>
            {elements.map((e, index) => {
                let el = <></>;
                switch (e.type) {
                    case 'memo-game':
                        el = (
                            <MemoryGame></MemoryGame>
                        );
                        break;

                    case 'image-lottery':
                        el = (
                            <ImageLottery></ImageLottery>
                        );
                        break;

                    case 'document':
                        el = (
                            <DocumentPrezenter
                                document={e.params.document}
                                thumbnails={e.params.thumbnails}
                                altLabel={e.params.altLabel}
                                format={e.params.format}
                            />
                        );
                        break;

                    case 'audio':
                        el = (
                            <AudioPlayer
                                src={e.params.src}
                            />
                        );
                        break;

                    case 'puzzle':
                        el = (
                            <Puzzle code={e.params.code} />
                        );
                        break;

                    case 'youtube':
                        el = (
                            <YouTubeEmbed
                                id={e.params.id}
                            ></YouTubeEmbed>
                        );
                        break;

                    case 'words-game':
                        el = (
                            <WordsGame />
                        );
                        break;

                    case 'image-slider':
                        el = (
                            <ImageSlider />
                        );
                        break;

                    case 'shadows-game':
                        el = (
                            <ShadowsGame />
                        );
                        break;

                    case 'audio-quiz':
                        el = (
                            <AudioQuiz />
                        );
                        break;

                    case 'korony-game':
                        el = (
                            <KoronyGame />
                        );
                        break;

                    case 'plants':
                        el = (
                            <Plants />
                        );
                        break;

                    case 'fishes':
                        el = (
                            <Fishes />
                        );
                        break;

                    default:
                        console.error('Unknow element type:', e.type);
                        el = (<></>);
                }

                return <React.Fragment key={index}>{el}</React.Fragment>
            })}
        </>
    )
}

export default TaskElements;
