export function getHomeRoute() {
    return '/';
}

export function getMapRoute() {
    return '/turniej'
}

export function getTaskRoute(id: string | number = ':id') {

    return taskIDToUri(String(id)) || `/zadanie/${id}`
}

export function getGeneratorRoute() {
    return '/zamek';
}

export function getManualRoute() {
    return '/instrukcja';
}

export function getRulesRoute() {
    return '/zasady';
}

export function getLoginRoute() {
    return '/zapisy';
}

export function getEndRoute() {
    return '/koniec'
}


export const TASK_TO_URI_TABLE: [string, string][] = [
    ['101', '/sztuka/zadanie/portret'],
    ['102', '/sztuka/zadanie/opowiesci'],
    ['103', '/sztuka/zadanie/piosenka'],
    ['104', '/sztuka/zadanie/ukladanka'],
    ['105', '/sztuka/zadanie/malowane'],

    ['201', '/kultury/zadanie/slowa'],
    ['202', '/kultury/zadanie/nakrycia'],
    ['203', '/kultury/zadanie/drejdel'],
    ['204', '/kultury/zadanie/pomniki'],
    ['205', '/kultury/zadanie/paleczki'],
    ['206', '/kultury/zadanie/pamiatki'],

    ['301', '/przyroda/zadanie/cienie'],
    ['302', '/przyroda/zadanie/druzyna'],
    ['303', '/przyroda/zadanie/glosy'],
    ['304', '/przyroda/zadanie/kolory'],
    ['305', '/przyroda/zadanie/eko'],
    ['306', '/przyroda/zadanie/pamiec'],

    ['401', '/muzeum/zadanie/odpocznij'],
    ['402', '/muzeum/zadanie/korona'],
    ['403', '/muzeum/zadanie/lecznicze'],
    ['404', '/muzeum/zadanie/koszerne'],
    ['405', '/muzeum/zadanie/besamimka'],
    ['406', '/muzeum/zadanie/roznice'],
    ['407', '/muzeum/zadanie/wizyta'],
]

export function taskIDToUri(id: string): string | null {
    const reference = TASK_TO_URI_TABLE.find(data => data[0] === id);
    return reference? reference[1] : null;
}
