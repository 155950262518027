import React from "react";
import './style.scss';

export default function PageFooter() {
    return (
        <footer className="page-footer">
            <h3>DARCZYŃCY</h3>
            <nav className='logo-list'>
                <a href="https://eeagrants.org/" className="grants">
                    <img src="/images/logo/grants.png" alt="Iceland Liechtenstein Norway Grants" />
                </a>
                <a href="https://www.gov.pl/web/kultura" className="ministrestwo">
                    <img src="/images/logo/ministerstwo.png" alt="Ministerstwo Kultury i Dziedzictwa Narodowego" />
                </a>
                <a href="https://polin.pl/" className="polin">
                    <img src="/images/logo/polin.png" alt="Polin - Żydowskie Dziedzictwo Kulturowe" />
                </a>
            </nav>
            <p>Wspólnie działamy na rzecz Europy zielonej, konkurencyjnej i sprzyjającej integracji społecznej</p>
            <div className="logo-list-2">
                <img src="/images/logo/parasol.png" alt="Fundacja Rodzinny Parasol"/>
                <img src="/images/logo/instytut-historyczny.png" alt="Stowarzyszenie Żydowski Instytut Historyczny w Polsca"/>
            </div>
        </footer>
    )
}
