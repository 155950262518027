import { GameModuleState } from "../../modules/game";
import { forwardRef, useRef, useState, useCallback, useEffect, useImperativeHandle } from "react";
import React from "react";
import useUser from "../../modules/user/hooks/use-user";
import useUserAwards from "../../modules/user/hooks/use-user-awards";
import createGame, { GameConfig } from "./game";


const GENERATOR_KEY = 'dd2020-gen'

type Props = {
    game: GameModuleState
}

type API = {
    getImage: () => Promise<string>,
    reset: () => void
}

const GameComponent = forwardRef<API, Props>(
    ({ game }, ref) => {
        const generatorContainer = useRef<HTMLDivElement>(null);

        const { name, emblem } = useUser();
        const userAwards = useUserAwards();

        const saveState = useCallback((state: GameConfig['state']) => {
            window.localStorage.setItem(GENERATOR_KEY, JSON.stringify(state));
        }, [])

        const getSavedState = useCallback(() => {
            try {
                return JSON.parse(window.localStorage.getItem(GENERATOR_KEY) || '{stickers: []}');
            } catch (e) {
                return {
                    stickers: []
                }
            }
        }, []);

        const [gameAPI, setGameAPI] = useState<{
            getImage: () => Promise<string>,
            reset: () => void
        }>()

        useImperativeHandle(ref, () => ({
            getImage() {
                if (!gameAPI) return Promise.resolve('');
                return gameAPI.getImage();
            },
            reset() {
                if (gameAPI) {
                    gameAPI.reset();
                }
            }
          }));

        // Inicjalizacja gry;
        useEffect(() => {

            const gameConfig = {
                container: generatorContainer.current,

                objects: game.generator.stickers.map(s => mapStickers(s, userAwards, game.tasks)),
                state: getSavedState() || [],

                onChange: saveState,
                userName: name || '',
                emblem: emblem || ''
            }

            const {
                getSceenBlob,
                uninstall,
                reset
            } = createGame(gameConfig)

            setGameAPI({
                getImage: getSceenBlob,
                reset
            })

            return uninstall;

        }, [game, emblem, getSavedState, name, saveState, userAwards])

        return (
            <div ref={generatorContainer} className="gameContainer"></div>
        )
    }
);

export default GameComponent;


function mapStickers(sticker: { src: string, id: string, iconSrc: string }, userAwards: string[], tasks: Task[]) {

    const task = tasks.find(t => t.awards.find(a => a === sticker.id));

    return {
        src: sticker.src,
        iconSrc: sticker.iconSrc,
        key: sticker.id,
        isAvaiable: userAwards.indexOf(sticker.id) >= 0,
        // isAvaiable: true,
        unavaialbeLabel: task ? task.title : ''
    }
}
