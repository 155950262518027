import React, { useEffect } from "react";
import Generator2 from "../../components/generator";
import CenterLolumnLayout from "../../layout/screen";
import { useSetPatternBackground } from "../../layout/background";

export default function GeneratorPage() {

    const setBackground = useSetPatternBackground();

    useEffect(() => {
        setBackground('/images/backgrounds/pattern-2.jpg');
    }, [setBackground])

    return (
        <CenterLolumnLayout>
            <h1>Zamek</h1>
            <p>Oto zamek, który dostaliście od króla Maciusia, możecie go wypełniać nagrodami zdobytymi w trakcie turnieju.</p>
            <Generator2 />
        </CenterLolumnLayout>
    )
}
