import React from "react";
import CenterLolumnLayout from "../../layout/screen";
import './style.scss'
import PageFooter from "../../components/page-footer";
import { Link } from "react-router-dom";
import { getMapRoute, getGeneratorRoute } from "../../routes";

export default function EndPage() {
    return (
        <CenterLolumnLayout footer={PageFooter()}>
            <div className="end-page">
                <h1>Gratulacje!</h1>
                <p>
                    Zakończyliście swój turniej. Witajcie w gronie rycerzy i rycerek Króla Maciusia!<br />
                    Pamiętajcie, by na pamiątkę zabrać ze sobą obraz swojego zamku.
                </p>
                <p>
                    Jeśli nie skończyliście wszystkich zadań, możecie kontynuować swój rycerski trening. W tym celu powróćcie do mapy i wybierzcie nieukończone wyzwanie.
                </p>
                <p>
                    Król Maciuś z chęcią pozna Waszą opinię na temat turnieju. Kliknijcie w dymek, by odpowiedzieć na pytania.
                </p>

                <nav>
                    <div className="zamek">
                        <div>
                            <a href="//survio.com/survey/d/dziendziecka2020-polin">Zabawa była udana?</a>

                        </div>
                    </div>

                    <div className="buttons">
                        <Link className="button-big-blue" to={getMapRoute()}>Rozwiń mapę</Link>
                        <Link className="button-big-blue" to={getGeneratorRoute()}>Przejdź do zamku</Link>
                    </div>

                </nav>



            </div>

        </CenterLolumnLayout >
    )
}
