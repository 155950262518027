import CenterLolumnLayout from "../../layout/screen";
import React, { useEffect, useCallback, useRef } from "react";
import { useSetPatternBackground } from "../../layout/background";

// Ponieważ hook dla ie11 nie ma styli zostawiam tutaj zwykłego hooka do developmentu
// import { useForm } from "react-hook-form";

//@ts-ignore
import { useForm } from "react-hook-form/dist/react-hook-form.ie11";


import { useHistory } from "react-router";
import useSetUserName from "../../modules/user/hooks/use-set-user-name";
import { getMapRoute } from "../../routes";
import './style.scss';
import PageFooter from "../../components/page-footer";
import useSetUserEmblem from "../../modules/user/hooks/use-set-user-emblem";
import useUser from "../../modules/user/hooks/use-user";

export default function LoginPage() {

    const setBackground = useSetPatternBackground();

    useEffect(() => {
        setBackground('/images/backgrounds/pattern-1.jpg');
    })

    const { register, handleSubmit, errors } = useForm();
    const history = useHistory();
    const setUserName = useSetUserName();
    const setUserEmblem = useSetUserEmblem();

    const { name } = useUser();

    const onSubmit = useCallback((data: any) => {
        setUserName(data.name);
        setUserEmblem(data.emblem);
        history.push(getMapRoute());
    }, [history, setUserName, setUserEmblem])

    const inputName = useRef<HTMLElement | null>(null);


    return (
        <CenterLolumnLayout footer={PageFooter()}>
            <main className="login-page">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="name">
                        <label htmlFor="name">Wpiszcie swoje imię lub imiona członków Waszej drużyny:</label>
                        <div className="input-name-container">
                            <input defaultValue={name} type="text" id="name" name="name" autoComplete={'off'} ref={(e) => {
                                //@ts-ignore
                                register(e, { required: true })
                                inputName.current = e;
                            }} />
                        </div>
                        {errors.name && <p className="error">Wypełnienie zgłoszenia jest obowiązkowe</p>}
                    </div>

                    <fieldset className="emblem">
                        <legend>Wybierzcie Wasz herb.</legend>
                        <div className="emblem-list">
                            <input type="radio" id="emblem-1" name="emblem" value="ryba" aria-label="Herb ryba 1" ref={register} />
                            <label htmlFor="emblem-1" className="emblem-1" aria-label="Herb ryba 2" >
                                <img src="/images/user/medal1.png" alt="Herb ryba 3" />
                            </label>

                            <input type="radio" id="emblem-2" name="emblem" value="orzeł" aria-label="Herb orzeł" ref={register} />
                            <label htmlFor="emblem-2" className="emblem-2" aria-label="Herb orzeł">
                                <img src="/images/user/medal2.png" alt="Herb orzeł" />
                            </label>

                            <input type="radio" id="emblem-3" name="emblem" value="gryf" aria-label="Herb gryf" ref={register} />
                            <label htmlFor="emblem-3" className="emblem-3" aria-label="Herb gryf">
                                <img src="/images/user/medal3.png" alt="Herb gryf" />
                            </label>

                            <input type="radio" id="emblem-4" name="emblem" value="jeleń" aria-label="Herb jeleń" ref={register} />
                            <label htmlFor="emblem-4" className="emblem-4" aria-label="Herb jeleń">
                                <img src="/images/user/medal4.png" alt="Herb jeleń" />
                            </label>
                        </div>
                    </fieldset>

                    <div className="button-container">
                        <button type="submit" name="submit" className="button-big-blue">Zaczynamy!</button>

                    </div>
                </form>
            </main>
        </CenterLolumnLayout>
    )
}
