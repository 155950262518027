import React from "react";
import './style.scss'
import TaskElements from "../../taks-elements";

type Props = {
    task: Task
}

const Task104: React.FunctionComponent<Props> = ({ task }) => {

    return (
        <div className="task-screen column task-104">

            <h1>{task.title}</h1>

            <p>Dworzanie powiedzieli, że rycerki i rycerze muszą być dobrze ułożeni. Król po krótkim namyśle powiedział, że w takim razie całe wojsko ma ćwiczyć układanki. Generałowie się sprzeciwili, bo wcale nie układanki mieli na myśli. Jednak Maciuś im odpowiedział, że to rozkaz i koniec.</p>
            <p>Pokażcie królowi, że jesteście dobrze ułożeni, układając jego obraz.</p>

            <aside>
                <header>Instrukcja do układanki:</header>
                <dl>
                    <div><dt><img src="/tasks/104/info-1.png" alt="ikona 1" /></dt><dd>klikając w  tę ikonkę możecie wybrać ilość elementów układanki</dd></div>
                    <div><dt><img src="/tasks/104/info-2.png" alt="ikona 2" /></dt><dd>kliknięcie w tę ikonkę powoduje obrócenie elementów i utrudnia</dd></div>
                    <div><dt><img src="/tasks/104/info-3.png" alt="ikona 3" /></dt><dd>po kliknięciu w tę ikonę, możecie wybrać kolor tła</dd></div>
                    <div><dt><img src="/tasks/104/info-4.png" alt="ikona 4" /></dt><dd>po kliknięciu w ten guzik rozpoczniecie układanie</dd></div>
                </dl>
            </aside>

            <TaskElements
                elements={task?.elements || []}
            />

        </div>)
}

export default Task104;
